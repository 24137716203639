@font-face {
    font-family: 'RobotoLite';
    src: url('fonts/Roboto-Light-webfont.eot');
    src: url('fonts/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Roboto-Light-webfont.woff') format('woff'),
         url('fonts/Roboto-Light-webfont.ttf') format('truetype'),
         url('fonts/Roboto-Light-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoThin';
    src: url('fonts/Roboto-Thin-webfont.eot');
    src: url('fonts/Roboto-Thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Roboto-Thin-webfont.woff') format('woff'),
         url('fonts/Roboto-Thin-webfont.ttf') format('truetype'),
         url('fonts/Roboto-Thin-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

#kumobius-header {
	background: rgba(25, 25, 25, 0.8);
}
#header {
	background: rgba(0, 0, 0, 0.8);
}
#content {
	background: rgba(0, 0, 0, 0.8);
}
body.is-iphone #content, body.is-iphone #header {
	background: rgba(0, 0, 0, 0.45);
}

html {
	margin: 0;
	font-family: 'RobotoLite', sans-serif;
}

body {
	margin: auto;
}

html {
    margin: 0;
}
body {
}
body #header {
    width: 640px;
}
body #content {
    width: 536px;
	width: 640px;
    margin: auto;
	padding-top: 10px;
}
body.is-iphone * {
    max-width: 640px;
}
body #iphone {
    height: auto;
    /*height: 308px;*/
	border-top: 1px solid #111;
	border-bottom: 1px solid #111;
	height: 360px;
}
body #iphone iframe {
    margin: 0 0 0 52px;
}
body .feature-block {
	width: 640px;
    margin: 0;
    border: none;
    padding: 0;
    border-radius: 0;
	margin: 10px 0;
}

html.is-iphone {
    width: 640px;
}
body.is-iphone {
    margin: 0;
    width: 640px;
}
body.is-iphone #kumobius-header {
    display: none;
}

html.is-ipad {
}
body.is-ipad {
    -webkit-background-size: 1920px 1200px;
}
body.is-ipad #iphone iframe {
	margin: 0;
}

html.is-desktop {
}

body.is-desktop {
	
}
body.is-desktop #iphone iframe {
	margin: 0;	
}

body #iphone iframe {
	margin: 0 !important;
}

a {
	text-decoration: none;
	color: #23acff;
}

body a:hover {
	color: #23acff;
}

#kumobius-header {
	height: 25px;
	padding: 10px 0 20px 0;
	text-align: center;
	font-size: 21px;
	text-shadow: black 0px 2px 4px;
	color: gray;
	position: relative;
    margin: auto;
	z-index: 100;
}

#kumobius-header a {
	color: white;
    color: #ddd;
}

#header {
	width: 640px;
	margin: auto;
	position: relative;
	z-index: 100;
}

#content {
	width: 640px;
	margin: auto;
	z-index: 100;
	position: relative;
	
	margin-top: 20px;
}

#logo {
}

h1 {
	font-size: 50pt;
    line-height: 0;
	padding: 0;
	margin: 0;
}

#iphone {
	width: 640px;
	height: 350px;
}

#iphone iframe {
	margin-left: 92px;
	margin-top: 44px;
}

p {
	margin: 10px 0;
}

#purchase {
	margin: auto;
	position: relative;
	clear: both;
	margin-top: 10px;
	margin-bottom: 10px;
}

#purchase a {
	float: left;
	margin: 0 106px 20px;
	margin: 0 158px 20px;
}

#purchase #buy-now {
	width: 318px;
	height: 107px;
	font-size: 40px;
	line-height: 107px;
	font-family: courier;
	border: 3px solid black;
	border-radius: 10px;
	text-align: center;
	display: block;
	color: white;

background: #fa7070; /* Old browsers */
background: -moz-linear-gradient(top, #fa7070 0%, #ef6e67 10%, #e16253 25%, #e76453 37%, #ed6351 50%, #e44432 51%, #f2606a 83%, #fc7295 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fa7070), color-stop(10%,#ef6e67), color-stop(25%,#e16253), color-stop(37%,#e76453), color-stop(50%,#ed6351), color-stop(51%,#e44432), color-stop(83%,#f2606a), color-stop(100%,#fc7295)); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(top, #fa7070 0%,#ef6e67 10%,#e16253 25%,#e76453 37%,#ed6351 50%,#e44432 51%,#f2606a 83%,#fc7295 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(top, #fa7070 0%,#ef6e67 10%,#e16253 25%,#e76453 37%,#ed6351 50%,#e44432 51%,#f2606a 83%,#fc7295 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(top, #fa7070 0%,#ef6e67 10%,#e16253 25%,#e76453 37%,#ed6351 50%,#e44432 51%,#f2606a 83%,#fc7295 100%); /* IE10+ */
background: linear-gradient(to bottom, #fa7070 0%,#ef6e67 10%,#e16253 25%,#e76453 37%,#ed6351 50%,#e44432 51%,#f2606a 83%,#fc7295 100%); /* W3C */
}

#app-store-link {
	background: transparent url(images/app_badge_2.png) no-repeat 50% 50%;
	background-size: contain;
	width: 324px;
	height: 113px;
}
#play-store-link {
	background: transparent url(images/play_badge.png) no-repeat 50% 50%;
	background-size: contain;
	width: 324px;
	height: 113px;
}
#steam-link {
	background: transparent url(images/steam.png) no-repeat 50% 50%;
	background-size: contain;
	width: 324px;
	height: 113px;
}

.humble-widget {
    margin-top: 10px;
    margin-left: 120px;
    border: none;
}

#quotes-block {
	display: none;
	clear: both;
}

.feature-block {
	border: 5px solid black;
	float: left;
	margin: 20px 30px;
	position: relative;
	color: white;
	padding: 0 20px;
	font-size: 15pt;
	
	border-radius: 10px;
}

#story-block {
	float: right;
	margin-left: 10px;
}

#critical-block {
	float: left;
	margin-right: 10px;
}

#features-block {
	float: right;
	margin-left: 10px;
}

.feature-block h3 {
	margin: 0;
	padding: 0;
	line-height: 50px;
	color: white;
	font-size: 28px;
    text-align: center;
}

#footer {
	text-align: center;
	clear: both;
	padding: 10px 0 10px 0;
	color: #aaa;
	position: relative;
	z-index: 150;

    background: rgba(0,0,0,0.8);
    width: 640px;
    margin: 0 auto;
}

#footer a {
	color: #aaa;
}






.clear {
	clear: both;
}

#tagline {
	text-align: center;
	font-size: 20pt;
    line-height: 50px;
    margin: 0;
    padding: 0;
	
    color: white;
	text-shadow: #333 1px 2px 2px;
}

.headline-quote {
    color: white;
    height: 50px;
    text-align: center;
	padding-bottom: 10px;
}

.headline-quote div {
    width: 536px;
	width: 640px;
    height: 50px;
}

.quote {
    line-height: 25px;
    font-size: 17pt;
	text-shadow: #000 1px 2px 2px;
    clear: both;
}

.quote-source {
    color: #888;
	
}

#press-block .quote {
    padding: 15px 20px;
}

#press-block .quote a {
    float: right;
}

#screenshots-block {
	float: left;
    width: 536px;
    height: 302px;
    /*
    margin: 20px 0 0 0;
    */
}

#press-block {
    float: left;
	background: #111;
    padding-bottom: 10px;
}
    
body.is-iphone #screenshots-block {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}
    
#screenshots-block * {
    width: 536px;
    height: 302px;
}

.coming-soon {
    font-size: 23pt;
    text-align: center;
    border-radius: 15px;
    width: 318px;
    height: 106px;
    line-height: 106px;
    border: 3px solid #cfcfcf;
    margin: auto;
    color: white;
}

.coming-soon {
	padding-top: 16px;
	height: 90px;
	line-height: 35px;
}
.coming-soon a {
	font-size: 15pt;
}

#purchase .coming-soon a {
	float: none;
	margin: 0;
}

.link-list {
    text-align: center;
    color: #aaa;
    font-size: 18pt;
    padding: 10px 20px;
    clear: both;
}

.link-list a {
    color: white;
}

.logo-letter {
	width: 160px;
	height: 190px;
	line-height: 190px;
	font-size: 100pt;
	display: block;
	float: left;
	text-align: center;
	color: white;
	font-family: 'RobotoThin';
	font-weight: normal;
}

h1 {
	clear: both;
	height: 190px;
}

.letter-1 {

}

#gifs-block {
	margin-left: 2px;
	margin-top: 10px;
	margin-bottom: 10px;
}
#gifs-block * {
	width: 210px;
	height: 315px;
	
	display: block;
	float: left;
	margin: 0 1px;
}

body .description-block {
    padding: 0 20px;
    width: 496px;
	width: 600px;
    text-align: center;
    text-shadow: black 1px 2px 2px;
}

.awards-block {
	margin-left: 2px;
}
.award {
	width: 210px;
	height: 128px;
	margin: 0 1px;
	background: grey;
	float: left;
	background: url(images/award-wreath.png) no-repeat 50% 50%;

	text-align: center;
}

.award-title {
	line-height: 32px;
	height: 32px;
	margin-top: 32px;
	color: whiteSmoke;
}

.award-subtitle {
	line-height: 32px;
	height: 32px;
	font-size: 13pt;
	color: #ccc;
}

.compressed .award-title {
    margin-top: 25px;
}

.compressed .award-subtitle {
    line-height: 22px;
}

.extra-compressed .award-title {
    font-size: 16pt;
    margin-top: 20px;
    line-height: 30px;
}

.extra-compressed .award-subtitle {
    line-height: 22px;
    font-size: 10pt;
    margin-top: 30px;
}


.float-left {
	float: left;
}

.float-right {
	float: right;
}

.kumobius-header-link {
	padding: 0 20px;
}

#kumobius-header .kumobius-header-link a {
    color: #ddd;
}

.kumobius-header-link-center {
	width: 540px;
	margin: 0 auto;
}

.light-box {
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	margin: auto;
	top: 0px;
	z-index: 5002;
	width: 400px;
}

.light-box-background {
	display: none;
	background: rgba(0,0,0,0.5);
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 5001;
}

.light-box-close-button {
	position: absolute;
	top: 16px;
	right: 4px;
	width: 36px;
	height: 36px;
	line-height: 36px;
	text-align: center;
	color: #666;
	font-size: 36px;
	cursor: pointer;
	z-index: 5003;
}

body.is-iphone .light-box {
	width: 640px;
}

body.is-iphone #mc_embed_signup {
	width: 640px;
	font-size: 20pt;
}

body.is-iphone .light-box * {
	font-size: 22pt;
}

body.is-iphone #mc-embedded-subscribe {
	height: 64px;
	font-size: 22pt;
}

body.is-iphone .light-box-close-button {
	font-size: 72px;
	height: 72px;
	width: 72px;
	top: 32px;
}


.newsletter-badge-link {
    width: 320px;
    padding: 8px 0;
    color: white;
    font-size: 24pt;
    text-align: center;
    border-radius: 10px;
    border: 2px solid #aaa;

}

.footer-block {
    margin: 0 auto;
    padding: 10px 0;
    width: 640px;
}
