@font-face {
  font-family: RobotoLite;
  src: url("Roboto-Light-webfont.c33a28fd.eot");
  src: url("Roboto-Light-webfont.c33a28fd.eot#iefix") format("embedded-opentype"), url("Roboto-Light-webfont.d302c824.woff") format("woff"), url("Roboto-Light-webfont.7a42e51c.ttf") format("truetype"), url("Roboto-Light-webfont.6b8c1754.svg#robotoregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoThin;
  src: url("Roboto-Thin-webfont.f81beb90.eot");
  src: url("Roboto-Thin-webfont.f81beb90.eot#iefix") format("embedded-opentype"), url("Roboto-Thin-webfont.613c6cff.woff") format("woff"), url("Roboto-Thin-webfont.89ec2ae9.ttf") format("truetype"), url("Roboto-Thin-webfont.fe5a5ccc.svg#robotoregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

#kumobius-header {
  background: #191919cc;
}

#header, #content {
  background: #000c;
}

body.is-iphone #content, body.is-iphone #header {
  background: #00000073;
}

html {
  margin: 0;
  font-family: RobotoLite, sans-serif;
}

body {
  margin: auto;
}

html {
  margin: 0;
}

body #header {
  width: 640px;
}

body #content {
  width: 640px;
  margin: auto;
  padding-top: 10px;
}

body.is-iphone * {
  max-width: 640px;
}

body #iphone {
  border-top: 1px solid #111;
  border-bottom: 1px solid #111;
  height: 360px;
}

body .feature-block {
  border: none;
  border-radius: 0;
  width: 640px;
  margin: 10px 0;
  padding: 0;
}

html.is-iphone {
  width: 640px;
}

body.is-iphone {
  width: 640px;
  margin: 0;
}

body.is-iphone #kumobius-header {
  display: none;
}

body.is-ipad {
  -webkit-background-size: 1920px 1200px;
}

body.is-ipad #iphone iframe, body.is-desktop #iphone iframe {
  margin: 0;
}

body #iphone iframe {
  margin: 0 !important;
}

a {
  color: #23acff;
  text-decoration: none;
}

body a:hover {
  color: #23acff;
}

#kumobius-header {
  text-align: center;
  text-shadow: 0 2px 4px #000;
  color: gray;
  z-index: 100;
  height: 25px;
  margin: auto;
  padding: 10px 0 20px;
  font-size: 21px;
  position: relative;
}

#kumobius-header a {
  color: #ddd;
}

#header {
  z-index: 100;
  width: 640px;
  margin: auto;
  position: relative;
}

#content {
  z-index: 100;
  width: 640px;
  margin: 20px auto auto;
  position: relative;
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 50pt;
  line-height: 0;
}

#iphone {
  width: 640px;
  height: 350px;
}

#iphone iframe {
  margin-top: 44px;
  margin-left: 92px;
}

p {
  margin: 10px 0;
}

#purchase {
  clear: both;
  margin: 10px auto;
  position: relative;
}

#purchase a {
  float: left;
  margin: 0 158px 20px;
}

#purchase #buy-now {
  text-align: center;
  color: #fff;
  background: -moz-linear-gradient(top, #fa7070 0%, #ef6e67 10%, #e16253 25%, #e76453 37%, #ed6351 50%, #e44432 51%, #f2606a 83%, #fc7295 100%);
  background: -o-linear-gradient(top, #fa7070 0%, #ef6e67 10%, #e16253 25%, #e76453 37%, #ed6351 50%, #e44432 51%, #f2606a 83%, #fc7295 100%);
  background: -ms-linear-gradient(top, #fa7070 0%, #ef6e67 10%, #e16253 25%, #e76453 37%, #ed6351 50%, #e44432 51%, #f2606a 83%, #fc7295 100%);
  background: linear-gradient(#fa7070 0%, #ef6e67 10%, #e16253 25%, #e76453 37%, #ed6351 50%, #e44432 51%, #f2606a 83%, #fc7295 100%);
  border: 3px solid #000;
  border-radius: 10px;
  width: 318px;
  height: 107px;
  font-family: courier;
  font-size: 40px;
  line-height: 107px;
  display: block;
}

#app-store-link {
  background: url("app_badge_2.1b34e01c.png") 50% / contain no-repeat;
  width: 324px;
  height: 113px;
}

#play-store-link {
  background: url("play_badge.340919ae.png") 50% / contain no-repeat;
  width: 324px;
  height: 113px;
}

#steam-link {
  background: url("steam.6de4e166.png") 50% / contain no-repeat;
  width: 324px;
  height: 113px;
}

.humble-widget {
  border: none;
  margin-top: 10px;
  margin-left: 120px;
}

#quotes-block {
  clear: both;
  display: none;
}

.feature-block {
  float: left;
  color: #fff;
  border: 5px solid #000;
  border-radius: 10px;
  margin: 20px 30px;
  padding: 0 20px;
  font-size: 15pt;
  position: relative;
}

#story-block {
  float: right;
  margin-left: 10px;
}

#critical-block {
  float: left;
  margin-right: 10px;
}

#features-block {
  float: right;
  margin-left: 10px;
}

.feature-block h3 {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 28px;
  line-height: 50px;
}

#footer {
  text-align: center;
  clear: both;
  color: #aaa;
  z-index: 150;
  background: #000c;
  width: 640px;
  margin: 0 auto;
  padding: 10px 0;
  position: relative;
}

#footer a {
  color: #aaa;
}

.clear {
  clear: both;
}

#tagline {
  text-align: center;
  color: #fff;
  text-shadow: 1px 2px 2px #333;
  margin: 0;
  padding: 0;
  font-size: 20pt;
  line-height: 50px;
}

.headline-quote {
  color: #fff;
  text-align: center;
  height: 50px;
  padding-bottom: 10px;
}

.headline-quote div {
  width: 640px;
  height: 50px;
}

.quote {
  text-shadow: 1px 2px 2px #000;
  clear: both;
  font-size: 17pt;
  line-height: 25px;
}

.quote-source {
  color: #888;
}

#press-block .quote {
  padding: 15px 20px;
}

#press-block .quote a {
  float: right;
}

#screenshots-block {
  float: left;
  width: 536px;
  height: 302px;
}

#press-block {
  float: left;
  background: #111;
  padding-bottom: 10px;
}

body.is-iphone #screenshots-block {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

#screenshots-block * {
  width: 536px;
  height: 302px;
}

.coming-soon {
  text-align: center;
  color: #fff;
  border: 3px solid #cfcfcf;
  border-radius: 15px;
  width: 318px;
  height: 90px;
  margin: auto;
  padding-top: 16px;
  font-size: 23pt;
  line-height: 35px;
}

.coming-soon a {
  font-size: 15pt;
}

#purchase .coming-soon a {
  float: none;
  margin: 0;
}

.link-list {
  text-align: center;
  color: #aaa;
  clear: both;
  padding: 10px 20px;
  font-size: 18pt;
}

.link-list a {
  color: #fff;
}

.logo-letter {
  float: left;
  text-align: center;
  color: #fff;
  width: 160px;
  height: 190px;
  font-family: RobotoThin;
  font-size: 100pt;
  font-weight: normal;
  line-height: 190px;
  display: block;
}

h1 {
  clear: both;
  height: 190px;
}

#gifs-block {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
}

#gifs-block * {
  float: left;
  width: 210px;
  height: 315px;
  margin: 0 1px;
  display: block;
}

body .description-block {
  text-align: center;
  text-shadow: 1px 2px 2px #000;
  width: 600px;
  padding: 0 20px;
}

.awards-block {
  margin-left: 2px;
}

.award {
  float: left;
  text-align: center;
  background: url("award-wreath.3fa7472b.png") 50% no-repeat;
  width: 210px;
  height: 128px;
  margin: 0 1px;
}

.award-title {
  color: #f5f5f5;
  height: 32px;
  margin-top: 32px;
  line-height: 32px;
}

.award-subtitle {
  color: #ccc;
  height: 32px;
  font-size: 13pt;
  line-height: 32px;
}

.compressed .award-title {
  margin-top: 25px;
}

.compressed .award-subtitle {
  line-height: 22px;
}

.extra-compressed .award-title {
  margin-top: 20px;
  font-size: 16pt;
  line-height: 30px;
}

.extra-compressed .award-subtitle {
  margin-top: 30px;
  font-size: 10pt;
  line-height: 22px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.kumobius-header-link {
  padding: 0 20px;
}

#kumobius-header .kumobius-header-link a {
  color: #ddd;
}

.kumobius-header-link-center {
  width: 540px;
  margin: 0 auto;
}

.light-box {
  z-index: 5002;
  width: 400px;
  margin: auto;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.light-box-background {
  z-index: 5001;
  background: #00000080;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  inset: 0;
}

.light-box-close-button {
  text-align: center;
  color: #666;
  cursor: pointer;
  z-index: 5003;
  width: 36px;
  height: 36px;
  font-size: 36px;
  line-height: 36px;
  position: absolute;
  top: 16px;
  right: 4px;
}

body.is-iphone .light-box {
  width: 640px;
}

body.is-iphone #mc_embed_signup {
  width: 640px;
  font-size: 20pt;
}

body.is-iphone .light-box * {
  font-size: 22pt;
}

body.is-iphone #mc-embedded-subscribe {
  height: 64px;
  font-size: 22pt;
}

body.is-iphone .light-box-close-button {
  width: 72px;
  height: 72px;
  font-size: 72px;
  top: 32px;
}

.newsletter-badge-link {
  color: #fff;
  text-align: center;
  border: 2px solid #aaa;
  border-radius: 10px;
  width: 320px;
  padding: 8px 0;
  font-size: 24pt;
}

.footer-block {
  width: 640px;
  margin: 0 auto;
  padding: 10px 0;
}

/*# sourceMappingURL=index.3ef99c99.css.map */
